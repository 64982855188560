// React and hooks
import React, { memo, useCallback, useEffect } from "react";

// Redux imports
import { useDispatch, useSelector } from 'react-redux';
// Redux actions and selectors
import { updateFieldValues, getDropDownListData, getStatementManagementTableData, initialStateStatementManagementTab1, queueStatementsForAutomatedRelease } from '../StatementManagmentRedux/statementManagementSlice';

// Locals and internationalization
import i18n from "../../../../utilities/i18n";

// Third-party libraries
import { Form } from 'react-bootstrap';
import { format } from 'date-fns';
import { Tooltip } from "@mui/material";

// Custom Components
import PatientDropDownSearch from '../../patients/patientDropDownSearch';
import PatientsAdvancedSearch from '../../patients/PatientsAdvancedSearch';
import RightSidePanel from '../../../commons/RightSidePanel/RightSidePanel';
import CalendarInput from "../../../commons/input/CalendarInput";
import { MaterialMultiselect } from "../../../../MaterialMultiselect/MaterialMultiselect";
import RangeInput from "../../../commons/input/RangeInput";
import CommonButton from "../../../commons/Buttons";
import Pagination from "../../../commons/pagination";
import Table from "../../../commons/Table/Table";
import QueuedItemsDisplay from "./QueuedItemsDisplay";
import CustomizedDialogs from "../../../modalWindowComponent/CustomizedDialogs";

// Custom Hooks
import { useApiNotifyHandler, useLoadingBarEffect, useTableDataUpdate } from "../hooks";

// Utils & Constants
import { StatementManagementTableBodyData, StatementManagementTableConfig } from "./StatementManagementTable"
import { PAGING_END_INDEX } from "../../../../utilities/staticConfigs";
import { checkPermission } from "../../../../utilities/commonUtilities";
import { permission_key_values_patient } from "../../../../utilities/permissions";

// Memoized Table Component
// Caching the report table to avoid un-necessary table render
const MemoizedStatementDataTable = memo(({ tableObject, onCheckBoxClick, checkedHeaderCheckBox
}) => {
	return (
		<div className="table-responsive">
			<Table
				tableObject={tableObject}
				onClickGridCheckBox={onCheckBoxClick}
				checkedHeaderCheckBox={checkedHeaderCheckBox}
			/>
		</div>
	);
});
MemoizedStatementDataTable.displayName = 'MemoizedStatementDataTable';



/**
 * @component
 * @description The tab-1 of the statement management page
 * @param {func} showNotifyWindow notify function
 * @param {func} setShowLoadingBar loading bar context method
 * @param {Number} practicePk current session practice id
 * @returns JSX
 */
function PatientsStatementManagement({
	practicePk,
	setShowLoadingBar,
	showNotifyWindow

}) {
	const dispatch = useDispatch();

	const {
		tableData,
		selectedEntries,
		queuedItems,
		pagination,
		storedFiltersForPagination,
		apiState,
		filters,
		showQueuedItemsModal
	} = useSelector(state => state.statementMgData.statementManagementTabData);

	const {
		isFilterDropDownDataReceived,
		InsuranceNameList,
		ProviderList,
		FacilityList,
		BillPRList,
		ClaimStatusList,
	} = useSelector(state => state.statementMgData.dropDownListData)

	const tableKey = useTableDataUpdate(tableData, StatementManagementTableBodyData, StatementManagementTableConfig);
	useLoadingBarEffect(apiState, setShowLoadingBar);
	useApiNotifyHandler('statementManagementTabData', apiState, showNotifyWindow);

	/**
	 * @description Load Select box options and table data on comp mount
	 * Dispatches Redux Thunk function
	 */
	useEffect(() => {
		if (!isFilterDropDownDataReceived)
			dispatch(getDropDownListData());
		if (!tableData.length) {
			dispatch(getStatementManagementTableData({
				filters: storedFiltersForPagination,
				practicePk,
				pagination
			}));
		}
	}, []);


	/**
	 * @description Handle Patient Async Type Head Input Change
	 * @param {string} field to update 
	 * @param {Array || boolean} value 
	 * @returns 
	 */
	const handlePatientChange = (field, value) => {
		const updatedValue = Array.isArray(value) && value.length ? value : [];
		const patient_pk = Array.isArray(value) && value[0]?.id ? value[0]?.id : "";

		const newFilters = { ...filters };

		switch (field) {
			case 'patientSelected':
				newFilters["patient_selected"] = updatedValue;
				newFilters["patient_pk"] = patient_pk
				newFilters["patientAdvSearchData"] = updatedValue;
				break;
			case 'patientAdvSearchData':
				newFilters["patientAdvSearchData"] = value;
				break;
			case 'patientRemoved':
				if (value === true) {
					newFilters["patient_selected"] = [];
					newFilters["patient_pk"] = "";
					newFilters["patientAdvSearchData"] = [];
				}
				break;
			default:
				return;
		}

		if (field !== 'patientRemoved') {
			dispatch(updateFieldValues({
				state: 'statementManagementTabData',
				field: 'filters',
				value: newFilters
			}));
		} else if (field === 'patientRemoved' && value === true) {
			dispatch(updateFieldValues({
				state: 'statementManagementTabData',
				field: 'filters',
				value: {
					...newFilters,
					patient_pk: "",
					patient_selected: [],
					patientAdvSearchData: null
				}
			}));
		}
	};


	/**
	 * Handles changes to input fields in the Statement Release Tracker filters.
	 * @param {string} field - The name of the field being updated.
	 * @param {*} value - The new value for the field. Can be of any type, depending on the input.
	 * @description
	 * It handles special formatting for date fields, converting them to the 'YYYY-MM-DD' format.
	 * For all other fields, it uses the value as-is.
	 * @dispatches
	 * The function dispatches the `updateFieldValues` action to update the Redux store.
	 */
	const handleInputChange = (field, value) => {
		let serializedValue = value;

		const dateFields = ['dos_from', 'dos_to', 'entered_from', 'entered_to']
		if (dateFields.includes(field)) {
			serializedValue = (value && !isNaN(value)) ? format(value, 'yyyy-MM-dd') : null;
		}

		dispatch(updateFieldValues({
			state: 'statementManagementTabData',
			field: 'filters',
			value: { ...filters, [field]: serializedValue }
		}));
	};


	/**
	 * Resets all filters, pagination, and table data to their initial states,
	 * then fetches fresh data from the API.
	 */
	const handleReset = () => {
		// First lets reset the statementManagementTabData state
		dispatch(updateFieldValues({
			state: 'mainState',
			field: 'statementManagementTabData',
			value: { ...initialStateStatementManagementTab1, storedFiltersForPagination: initialStateStatementManagementTab1.filters }
		}));

		// Then, call the api to load fresh data
		setTimeout(() => {
			dispatch(getStatementManagementTableData({
				filters: initialStateStatementManagementTab1.filters,
				practicePk,
				pagination: initialStateStatementManagementTab1.pagination
			}));
		}, 150);
	};


	/**
	 * Handle Run report
	 * @dispatches
	 * -getStatementReleaseTrackerData Dispatches Redux async thunk method with filter values
	 * Also, keeps a copy of the current filter values for pagination purpose
	 */
	const handleRunReport = () => {
		dispatch(getStatementManagementTableData({
			filters,
			practicePk,
			pagination: initialStateStatementManagementTab1.pagination
		}));
		// Storing the current filters as a back-up so for the pagination this state can be used
		dispatch(updateFieldValues({
			state: 'statementManagementTabData',
			field: 'storedFiltersForPagination',
			value: filters
		}));
		// Resetting the selectedEntries state
		dispatch(updateFieldValues({
			state: 'statementManagementTabData',
			field: 'selectedEntries',
			value: initialStateStatementManagementTab1.selectedEntries
		}));
	}


	/**
	 * Handles the action when the user clicks the previous page button.
	 * It dispatches actions to fetch the previous page of data and update the pagination state.
	 */
	const onPagePrevious = () => {
		const previousPage = pagination.startIndex + 1 - PAGING_END_INDEX;
		let newStartIndex = pagination.startIndex - PAGING_END_INDEX;
		let newEndIndex = pagination.endIndex - PAGING_END_INDEX;

		dispatch(getStatementManagementTableData({
			filters: storedFiltersForPagination,
			practicePk,
			pagination: {
				...pagination,
				page: previousPage,
				startIndex: newStartIndex,
				endIndex: newEndIndex
			}
		}));
	};

	/**
	 * Handles the action when the user clicks a specific page number.
	 * It dispatches an action to fetch the data for the selected page and updates pagination accordingly.
	 * @param {Event} e - The event object from the click action
	 */
	const onPageUp = (e) => {
		const page = Number(e.target.id);

		dispatch(getStatementManagementTableData({
			filters: storedFiltersForPagination,
			practicePk,
			pagination: {
				...pagination,
				page,
			}
		}));
	};

	/**
	 * Handles the action when the user clicks the next page button.
	 * It dispatches actions to fetch the next page of data and update the pagination state.
	 */
	const onPageNext = () => {
		const nextPage = pagination.startIndex + 1 + PAGING_END_INDEX;
		if (nextPage <= pagination.total_pages) {
			let newStartIndex = pagination.startIndex + PAGING_END_INDEX;
			let newEndIndex = Math.min(pagination.endIndex + PAGING_END_INDEX, pagination.total_pages);

			dispatch(getStatementManagementTableData({
				filters: storedFiltersForPagination,
				practicePk,
				pagination: {
					...pagination,
					page: nextPage,
					startIndex: newStartIndex,
					endIndex: newEndIndex
				}
			}));
		}
	};


	/**
	 * Handles checkbox click events in the statement management table.
	 * @param {React.ChangeEvent<HTMLInputElement>} e - The checkbox change event.
	 * @param {'header' | 'data'} calledFrom - Indicates whether the checkbox event triggered from table header or body.
	 * @param {number} [id] - The ID of the clicked row entity (only applicable for body checkboxes).
	 */
	const onCheckBoxClick = useCallback((e, calledFrom, id) => {
		// Get the checked state of the clicked checkbox
		const isChecked = e.target.checked;
		// Create a Set of IDs for all items on the current page
		const currentPageIds = new Set(tableData.map(item => item.id));

		if (calledFrom === 'header') {
			// Handle header checkbox click

			// If checked, add all current page IDs to selectedEntries
			// If unchecked, remove all current page IDs from selectedEntries
			const newSelectedEntries = isChecked
				? [...new Set([...selectedEntries, ...currentPageIds])]
				: selectedEntries.filter(id => !currentPageIds.has(id));

			// Update the checked state for all items on the current page
			const newTableData = tableData.map(item => ({ ...item, checked: isChecked }));

			// Dispatch actions to update selectedEntries and tableData in the Redux store
			dispatch(updateFieldValues({
				state: 'statementManagementTabData',
				field: 'selectedEntries',
				value: newSelectedEntries
			}));

			dispatch(updateFieldValues({
				state: 'statementManagementTabData',
				field: 'tableData',
				value: newTableData
			}));
		} else if (calledFrom === 'data' && id !== undefined) {
			// Handle individual row checkbox click

			// If checked, add the ID to selectedEntries
			// If unchecked, remove the ID from selectedEntries
			const newSelectedEntries = isChecked
				? [...selectedEntries, id]
				: selectedEntries.filter(entryId => entryId !== id);

			// Update the checked state for the clicked item in the table data
			const newTableData = tableData.map(item =>
				item.id === id ? { ...item, checked: isChecked } : item
			);

			// Dispatch actions to update selectedEntries and tableData in the Redux store
			dispatch(updateFieldValues({
				state: 'statementManagementTabData',
				field: 'selectedEntries',
				value: newSelectedEntries
			}));

			dispatch(updateFieldValues({
				state: 'statementManagementTabData',
				field: 'tableData',
				value: newTableData
			}));
		}
	}, [tableData, selectedEntries]);


	/**
	 * Checks if all entries in the table are selected.
	 * @returns {boolean} True if all entries are selected, false otherwise.
	 */
	const isAllChecked = () => {
		return tableData.every(item => selectedEntries.includes(item.id));
	};


	/**
	 * Handles the action of releasing selected patient statements for automated processing.
	 * This function dispatches the queueStatementsForAutomatedRelease action with the currently selected entries.
	 * 
	 * @function handleReleaseSelected
	 * @returns {void}
	 */
	const handleReleaseSelected = () => {
		if (checkPermission(permission_key_values_patient.statement_management_send_patient)) {
			dispatch(queueStatementsForAutomatedRelease(selectedEntries));
		} else {
			showNotifyWindow('show', 'error', i18n.t('errorMessages.permission_error'));
		}
	};


	/**
	 * @description Controller to close the queued items modal
	 */
	const handleCloseQueuedItemsModal = () => {
		// Update the modal state to close
		dispatch(updateFieldValues({
			state: 'statementManagementTabData',
			field: 'showQueuedItemsModal',
			value: false
		}));
		dispatch(updateFieldValues({
			state: 'statementManagementTabData',
			field: 'selectedEntries',
			value: []
		}));
		// Then load the fresh table data from the server, against the current query
		dispatch(getStatementManagementTableData({
			filters: storedFiltersForPagination,
			practicePk,
			pagination: initialStateStatementManagementTab1.pagination
		}));
	}

	return (
		<div>
			<Form autoComplete="off" className="searchBox pl-4 pb-5 pr-4">
				<div className="row padding-bottom10 ">
					<PatientDropDownSearch
						setPatientPK={(value) => handlePatientChange('patient_pk', value)}
						patientSelected={filters.patient_selected}
						setPatientSelected={(value) => handlePatientChange('patientSelected', value)}
						patientAdvSearchData={filters.patientAdvSearchData}
						setPatientRemoved={(value) => handlePatientChange('patientRemoved', value)}
						dateFieldClass={"margin-right20"}
					></PatientDropDownSearch>
					<div className="col justify-right">
						<div className="input-content-box padding-top22 justify-right">
							<RightSidePanel
								title={i18n.t("commons.advancedSearch")}
								onclickLabel={i18n.t("commons.advancedSearch")}
							>
								<PatientsAdvancedSearch
									setPatientPK={(value) => handlePatientChange('patient_pk', value)}
									setPatientSelected={(value) => handlePatientChange('patientSelected', value)}
									setPatientAdvSearchData={(value) => handlePatientChange('patientAdvSearchData', value)}
								></PatientsAdvancedSearch>
							</RightSidePanel>
						</div>
					</div>
				</div>

				<div className="row padding-top12">

					<div className="col-3">
						<CalendarInput
							label={i18n.t("statementManagement.dosFrom")}
							name="dos_from"
							id="dos_from"
							selected={filters.dos_from ? new Date(filters.dos_from) : null}
							maxDate={new Date()}
							onValueChange={(date) => handleInputChange('dos_from', date)}
						/>
					</div>

					<div className="col-3">
						<CalendarInput
							label={i18n.t("statementManagement.dosTo")}
							name="dos_to"
							id="dos_to"
							selected={filters.dos_to ? new Date(filters.dos_to) : null}
							maxDate={new Date()}
							minDate={filters.dos_from ? new Date(filters.dos_from) : null}
							onValueChange={(date) => handleInputChange('dos_to', date)}
						/>
					</div>

					<div className="col-3">
						<CalendarInput
							label={i18n.t("statementManagement.claimFrom")}
							name="entered_from"
							id="entered_from"
							selected={filters.entered_from ? new Date(filters.entered_from) : null}
							maxDate={new Date()}
							onValueChange={(date) => handleInputChange('entered_from', date)}
						/>
					</div>

					<div className="col-3">
						<CalendarInput
							label={i18n.t("statementManagement.claimTo")}
							name="entered_to"
							id="entered_to"
							selected={filters.entered_to ? new Date(filters.entered_to) : null}
							maxDate={new Date()}
							minDate={filters.entered_from ? new Date(filters.entered_from) : null}
							onValueChange={(date) => handleInputChange('entered_to', date)}
						/>
					</div>
				</div>

				<div className="row">

					<div className="col-3 padding-top7">
						<MaterialMultiselect
							name="insurances"
							value={filters.insurances}
							onValueChange={(e) => handleInputChange('insurances', e.target?.value)}
							options={InsuranceNameList}
							label={i18n.t("statementManagement.selectInsurance")}
						/>
					</div>

					<div className="col-3 padding-top7">
						<RangeInput
							name1="claim_balance_from"
							name2="claim_balance_to"
							value1={filters.claim_balance_from}
							value2={filters.claim_balance_to}
							onValueChange1={(e) => handleInputChange('claim_balance_from', e.target?.value)}
							onValueChange2={(e) => handleInputChange('claim_balance_to', e.target?.value)}
							label={i18n.t("statementManagement.claimBlnce")}
							ValidationMsg={(i18n.t("commons.balanceError"))}
						/>
					</div>

					<div className="col-3 padding-top7">
						<MaterialMultiselect
							name="providers"
							id="providers"
							value={filters.providers}
							onValueChange={(e) => handleInputChange('providers', e.target?.value)}
							options={ProviderList}
							label={i18n.t("statementManagement.seleProvider")}
						/>
					</div>

					<div className="col-3 padding-top7">
						<MaterialMultiselect
							name="facilities"
							id="facilities"
							value={filters.facilities}
							onValueChange={(e) => handleInputChange('facilities', e.target?.value)}
							options={FacilityList}
							label={i18n.t("statementManagement.selFacility")}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-3 padding-top7">
						<MaterialMultiselect
							name="bill_pr_codes"
							id="bill_pr_codes"
							value={filters.bill_pr_codes}
							onValueChange={(e) => handleInputChange('bill_pr_codes', e.target?.value)}
							options={BillPRList}
							label={i18n.t("statementManagement.billPR")}
						/>
					</div>

					<div className="col-3 padding-top7">
						<MaterialMultiselect
							name="claim_status"
							value={filters.claim_status}
							onValueChange={(e) => handleInputChange('claim_status', e.target?.value)}
							options={ClaimStatusList}
							label={i18n.t("statementManagement.clmStatus")}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12 div-float-right">
						<div className="form-group padding-top25">
							<div className="div-float-right">
								<span className="margin-right15" >
									<CommonButton
										onClick={handleReset} variant="outlined" label={i18n.t("claimsFilter.reset")}
									/>
								</span>

								<CommonButton
									onClick={handleRunReport}
									variant="contained"
									label={i18n.t("statementManagement.runStatement")}
								/>
							</div>
						</div>
					</div>
				</div>
			</Form>

			<div className="container-fluid mt-5">
				<div className="row justify-content-end">
					<div className="col-auto">
						<CommonButton
							disabled={true}
							onClick={() => { }}
							variant="outlined"
							label="Print Using Billi Template"
							icon="print"
						/>
					</div>

					<div className="col-auto">
						<Tooltip
							title="This action will update their claim status to 'Print Statement' and include them in the upcoming automated release cycle based on your practice configuration."
							arrow
							placement="top">
							<span>
								<CommonButton
									onClick={handleReleaseSelected}
									variant="contained"
									label="Queue for DMA Release"
									icon="assignment"
									disabled={!selectedEntries.length}
								/>
							</span>
						</Tooltip>
					</div>
				</div>
			</div>

			<div className='mt-2'>
				<MemoizedStatementDataTable
					key={tableKey}
					tableObject={StatementManagementTableConfig}
					onCheckBoxClick={onCheckBoxClick}
					checkedHeaderCheckBox={isAllChecked()}
				/>

				<Pagination
					totalPage={pagination.total_pages}
					activePage={pagination.page}
					startIndex={pagination.startIndex}
					endIndex={pagination.endIndex}
					onPagePrevious={onPagePrevious}
					onPageUp={onPageUp}
					onPageNext={onPageNext}
				/>
			</div>

			<CustomizedDialogs
				showModal={showQueuedItemsModal}
				header="Below Claims Queued successfully for statement release!"
				alertOK={handleCloseQueuedItemsModal}
				type="alert"
				setShowModalWindow={handleCloseQueuedItemsModal}
			>
				<QueuedItemsDisplay queuedItems={queuedItems} />
			</CustomizedDialogs>
		</div>
	);
}

export default PatientsStatementManagement;
